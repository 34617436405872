import useAuth from "@/modules/auth/composables/useAuth";
import {Ref, ref} from "vue";
import {v4 as uuid} from "uuid";
import {PurchaseOrderCatalogs, PurchaseOrderForm} from "@/modules/purchaseOrders/interfaces";
import store from "@/store";
import moment from "moment";

const {companyId} = useAuth()
const defaultValues = (): PurchaseOrderForm => {
    const id = uuid();

    return {
        id,
        reference: '',
        accountingCenterId: '',
        accountingAccountPayableId: '',
        locationId: '',
        providerId: '',
        paymentTerm: '',
        status: 'to_be_approved',
        creationDate: moment().format('DD/MM/YYYY'),
        companyId: companyId.value,
        subtotal: 0,
        discount: 0,
        tax: 0,
        total: 0,
        items: [
            {
                id: uuid(),
                categoryId: "",
                itemId: "",
                quantity: 1,
                unitId: "",
                unitConversionFactor: 1.00,
                unitPrice: 0,
                taxId: "",
                taxPercentage: 0,
                discountPercentage: 0.00,
                accountingAccountId: "",
                locationId: "",
                accountingCenterId: "",
                purchaseOrderId: id
            }
        ]
    }
}

const purchaseOrderForm: Ref<PurchaseOrderForm> = ref(defaultValues())

const usePurchaseOrder = () => {
    const clearForm = () => purchaseOrderForm.value = defaultValues()

    const createPurchaseOrder = async (purchaseOrderForm: PurchaseOrderForm) => {
        return await store.dispatch('purchaseOrders/createPurchaseOrder', purchaseOrderForm)
    }

    const updatePurchaseOrder = async (purchaseOrderForm: PurchaseOrderForm) => {
        return await store.dispatch('purchaseOrders/updatePurchaseOrder', purchaseOrderForm)
    }

    const getPurchaseOrder = async (id: string): Promise<PurchaseOrderForm> => {
        const {data} = await store.dispatch('purchaseOrders/getPurchaseOrder', id)

        return data;
    }

    const getCatalogs = async (): Promise<PurchaseOrderCatalogs> => {
        const {data} = await store.dispatch('purchaseOrders/getCatalogs', companyId.value)

        return data;
    }

    return {
        purchaseOrderForm,
        clearForm,
        createPurchaseOrder,
        updatePurchaseOrder,
        getPurchaseOrder,
        getCatalogs
    }
}

export default usePurchaseOrder;
