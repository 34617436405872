
import {onMounted, ref, watch} from "vue";
import usePurchaseOrder from "@/modules/purchaseOrders/composables/usePurchaseOrder";
import usePanel from "@/composables/usePanel";
import useSelect2 from "@/composables/useSelect2";

const {purchaseOrderForm} = usePurchaseOrder();
export default {
    props: ['catalogs', 'mode'],
    setup() {
        const {showPanel, togglePanel} = usePanel()
        const {select2center, select2provider, select2account, select2location} = useSelect2()
        const providerId = ref('')
        const paymentTerm = ref('')
        const creationDate = ref('')
        const accountingCenterId = ref('')
        const accountingAccountPayableId = ref('')
        const reference = ref('')
        const locationId = ref('')
        const status = ref('')


        const datepickerConfig = {
            singleDatePicker: true,
            showDropdowns: true,
            width: '100%',
            locale: {
                format: 'DD/MM/YYYY'
            },
            maxDate: new Date(),
        }

        onMounted(() => {
            providerId.value = purchaseOrderForm.value.providerId
            paymentTerm.value = purchaseOrderForm.value.paymentTerm
            creationDate.value = purchaseOrderForm.value.creationDate
            accountingCenterId.value = purchaseOrderForm.value.accountingCenterId
            accountingAccountPayableId.value = purchaseOrderForm.value.accountingAccountPayableId
            reference.value = purchaseOrderForm.value.reference
            locationId.value = purchaseOrderForm.value.locationId
            status.value = purchaseOrderForm.value.status
        })

        watch(providerId, val => purchaseOrderForm.value.providerId = val)
        watch(paymentTerm, val => purchaseOrderForm.value.paymentTerm = val)
        watch(creationDate, val => purchaseOrderForm.value.creationDate = val)
        watch(accountingCenterId, val => purchaseOrderForm.value.accountingCenterId = val)
        watch(accountingAccountPayableId, val => purchaseOrderForm.value.accountingAccountPayableId = val)
        watch(reference, val => purchaseOrderForm.value.reference = val)
        watch(locationId, val => purchaseOrderForm.value.locationId = val)
        watch(status, val => purchaseOrderForm.value.status = val)

        return {
            showPanel,
            togglePanel,
            providerId,
            paymentTerm,
            creationDate,
            accountingCenterId,
            accountingAccountPayableId,
            reference,
            locationId,
            status,
            select2provider,
            select2center,
            select2account,
            select2location,
            datepickerConfig,
            purchaseOrderForm
        }
    }
}
